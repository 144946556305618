<template>
  <b-modal id="cadastrarPlano" hide-footer no-close-on-backdrop no-close-on-esc centered size="md"
    title="Cadastrar Plano de Contas">

    <b-card>
      <b-alert :variant="getErrors.color" v-if="getErrors.hasMessage" show>
        <div class="alert-body" v-for="(error, index) in getErrors.messageArray" :key="index">
          {{ error[0] }}
        </div>

        <div class="alert-body">{{ getErrors.message }}</div>
      </b-alert>

      <div class="d-flex">
        <feather-icon icon="AlignCenterIcon" size="19" />
        <h4 class="mb-2 ml-50">Dados do Plano de Contas</h4>
      </div>

      <validation-observer ref="planoCadastroValidation">
        <b-form class="mt-1">
          <b-row>
            <b-col>
              <b-form-group label="Categoria Pertencente *" label-for="categoria">
                <validation-provider #default="{ errors }" name="Categoria Pertencente" rules="required">
                  <v-select id="categoria" v-model="form.plano_id" :options="optionsCategorias"
                    @input="selecionouPlanoPai"
                    :reduce="(plano) => plano.id" label="nome"
                    :selectable="(option) => option.posicao > 0 && option.posicao != 2">
                    <template #option="{ nome, posicao }">
                      <h5 v-if="posicao == 0" class="text-primary" style="margin: 0">
                        {{ nome }}
                      </h5>
                      <em v-else-if="posicao == 1" class="pl-2">{{ nome }}</em>
                      <em v-else class="pl-4"> - {{ nome }}</em>
                    </template>

                    <div slot="no-options">Categoria não encontrada!</div>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Nome *" label-for="nome">
                <validation-provider #default="{ errors }" id="nome" name="Nome" rules="required|max:80">
                  <b-form-input id="nome" v-model="form.nome" :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" :disabled="submit" @click="cadastrarPlano">
                <b-spinner small v-if="spinner" />
                <span v-if="spinner">Aguarde...</span>
                <span v-else>Cadastrar</span>
              </b-button>

              <b-button variant="outline-secondary" @click="$bvModal.hide('cadastrarPlano')">
                Fechar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  BRow,
  BSpinner,
} from "bootstrap-vue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";

// eslint-disable-next-line import/no-extraneous-dependencies
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BSpinner,
    BAlert,
    BModal,
    vSelect,
    BFormDatepicker,
  },

  data() {
    return {
      required,
      getErrors: {
        hasMessage: false,
        color: "danger",
        message: "",
        messageArray: "",
      },
      spinner: false,
      submit: false,
      errors: false,
      form: {
        id: "",
        nome: "",
        ordem: "",
        dre_id: null,
      },
      tipoDespesa: true,
    };
  },

  computed: {
    ...mapState({
      planos: (state) => state.planos.planos,
    }),

    ...mapGetters({
      despesasAgrupadas: "planos/despesasAgrupadas",
      receitasAgrupadas: "planos/receitasAgrupadas",
    }),

    optionsCategorias() {
      if (this.tipoDespesa)
        return this.despesasAgrupadas;
      else
        return this.receitasAgrupadas;
    },
  },

  methods: {
    selecionouPlanoPai(planoId){
      let plano = this.optionsCategorias.find(p => { return planoId == p.id } );
      this.SetPlanoPai(plano);
      this.SetOrdem(plano.ordem + 1);
    },
    SetPlanoPai(planoPai) {
      if (planoPai) {
        this.form.plano_id = planoPai.id;
        this.form.dre_id = planoPai.dre_id;
        this.SetOrdem(planoPai.filhos?.length + 1);
      } else {
        this.form.plano_id = null;
        this.form.dre_id = null;
        this.SetOrdem(1);
      }   
    },

    SetTipoDespesa(ehDespesa){
      this.tipoDespesa = ehDespesa;
    },

    SetPlanoReceitas(planoPai) {
      this.SetTipoDespesa(false);
      this.SetPlanoPai(planoPai);
    },

    SetPlanoDespesas(planoPai) {
      this.SetTipoDespesa(true);
      this.SetPlanoPai(planoPai);
    },

    SetOrdem(ordem) {
      this.form.ordem = ordem;
    },
    cadastrarPlano() {
      this.$refs.planoCadastroValidation.validate().then((success) => {
        if (success) {
          this.spinner = true;
          this.submit = true;
          this.errors = false;

          this.$http
            .post("adm/planos", this.form)
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Plano de Contas Cadastrado com Sucesso!",
                  icon: "InfoIcon",
                  variant: "success",
                },
              });
              this.getErrors.hasMessage = false;
              this.$refs.planoCadastroValidation.reset();
              this.resetarCampos();
              this.$store.dispatch("planos/listaPlanos");
            })
            .catch((error) => {
              let res = this.$responseMessages.getMessage(error, null);
              this.getErrors = res;
            })
            .finally(() => {
              this.spinner = false;
              this.submit = false;
            });
        }
      });
    },

    resetarCampos() {
      this.form.id = "";
      this.form.nome = "";
      this.form.ordem = this.form.ordem + 1;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped></style>
