<template>
  <b-row>
    <b-col md="6" sm="4" class="my-1">
      <b-button
        @click="$bvModal.show('cadastrarFornecedor')"
        variant="gradient-success"
      >
        <feather-icon class="mr-50" icon="UserPlusIcon" />
        <span class="align-middle">Adicionar Fornecedor</span>
      </b-button>
    </b-col>

    <b-col md="6" sm="8" class="my-1">
      <b-form-group
        label-cols-sm="3"
        label-align-sm="right"
        label-size="lg"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Procurar"
          />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">
              Limpar
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :items="fornecedores"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col md="4" class="mb-1">
                <strong>CPF/CNPJ : </strong>{{ row.item.cpf }}
                {{ row.item.cnpj }}
              </b-col>

              <b-col md="4" class="mb-1">
                <strong>EMAIL : </strong>{{ row.item.email }}
              </b-col>

              <b-col md="4" class="mb-1">
                <strong>TEL COMERCIAL : </strong>{{ row.item.tel_comercial }}
              </b-col>

              <b-col md="4" class="mb-1">
                <strong>TEL RESIDENCIAL : </strong
                >{{ row.item.tel_residencial }}
              </b-col>

              <b-col md="4" class="mb-1">
                <strong>CELULAR : </strong>{{ row.item.celular_um }}
              </b-col>

              <b-col md="4" class="mb-1">
                <strong>CELULAR WHATSAPP : </strong>{{ row.item.celular_dois }}
              </b-col>

              <b-col md="4" class="mb-1">
                <strong>CEP : </strong>{{ row.item.cep }}
              </b-col>

              <b-col md="4" class="mb-1">
                <strong>ENDEREÇO : </strong>{{ row.item.endereco }}
                {{ row.item.numero }}
              </b-col>

              <b-col md="4" class="mb-1">
                <strong>BAIRRO : </strong>{{ row.item.bairro }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>CIDADE : </strong>{{ row.item.cidade }}
              </b-col>

              <b-col md="8" class="mb-1">
                <strong>COMPLEMENTO : </strong>{{ row.item.complemento }}
              </b-col>

              <b-col md="4" class="mb-1">
                <strong>TEL COMERCIAL CONTATO : </strong
                >{{ row.item.contato_tel_comercial }}
              </b-col>

              <b-col md="4" class="mb-1">
                <strong>CELULAR CONTATO : </strong
                >{{ row.item.contato_celular }}
              </b-col>

              <b-col md="4" class="mb-1">
                <strong>EMAIL CONTATO : </strong>{{ row.item.contato_email }}
              </b-col>
            </b-row>
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Fechar Detalhes
            </b-button>
          </b-card>
        </template>
        <template #cell(opcoes)="data">
          <feather-icon
            class="mr-50"
            :class="data.detailsShowing ? 'text-primary' : 'text-default'"
            icon="EyeIcon"
            size="16"
            v-model="data.detailsShowing"
            @click="data.toggleDetails"
          />
          <feather-icon
            class="mr-50"
            icon="Edit2Icon"
            size="16"
            @click="editarFornecedor(data.item)"
          />
          <feather-icon
            class="mr-50 text-danger"
            icon="Trash2Icon"
            size="16"
            @click="excluirFornecedor(data.item.id, data.index)"
          />
        </template>
      </b-table>
    </b-col>

    <ModaCadastrarFornecedor />
    <ModalEditarFornecedor :form="editar" :fornecedores="fornecedores"/>
  </b-row>
</template>


<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BFormCheckbox,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BModal,
  VBModal,
} from "bootstrap-vue";
import ModaCadastrarFornecedor from "./CadastroFornecedores.vue";
import ModalEditarFornecedor from './ModalEditarFornecedor.vue'
import { mapState } from "vuex";


export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BModal,
    VBModal,
    BCard,
    BFormCheckbox,
    ModaCadastrarFornecedor,
    ModalEditarFornecedor,
  },
  directives: {
    "b-modal": VBModal,
  },

  data() {
    return {
      editar: [],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      boxTwo: "",
      fields: [
        { key: "fornecedor_nome", label: "Nome", sortable: true },
        "contato",
        { key: "celular_um", label: "Celular", sortable: false },
        { key: "celular_dois", label: "Celular WhatsApp", sortable: false },
        "email",
        { key: "opcoes", label: "Opções", sortable: false },
      ],
    };
  },

  computed: {
    ...mapState({
      fornecedores: (state) => state.fornecedores.fornecedores,
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },

  mounted() {
    this.$store.dispatch("fornecedores/listaFornecedores");
    this.$store.dispatch("planos/listaPlanos");
  },

  methods: {
    excluirFornecedor(id, index) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm(
          "Fornecedor será excluído, poderá ser revertido a exclusão!",
          {
            title: "Deseja realmente excluir esse Fornecedor ?",
            size: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((valor) => {
          if (valor) {
            this.$http
              .delete("adm/fornecedores/" + id)
              .then((res) => {
                this.$store.commit("fornecedores/DELETAR_FORNECEDOR", index);
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Fornecedor excluído com sucesso!",
                    icon: "InfoIcon",
                    variant: "success",
                  },
                });
              })
              .catch((error) => {
                this.$responseMessages.getMessage(error, null);
              });
          }
        });
    },
    editarFornecedor(fornecedor) {
      this.editar = fornecedor;
      this.$bvModal.show("editarFornecedor");
    },
  },
};
</script>

<style lang="scss">
</style>