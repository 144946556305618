<template>
  <b-modal
    id="cadastrarFornecedor"
    hide-footer
    size="xl"
    title="Cadastrar Fornecedor"
  >
    <b-card>
      <b-alert :variant="getErrors.color" v-if="getErrors.hasMessage" show>
        <div
          class="alert-body"
          v-for="(error, index) in getErrors.messageArray"
          :key="index"
        >
          {{ error[0] }}
        </div>

        <div class="alert-body">{{ getErrors.message }}</div>
      </b-alert>

      <!-- Header: Personal Info -->
      <div class="d-flex">
        <feather-icon icon="UserIcon" size="19" />
        <h4 class="mb-2 ml-50">Dados do Fornecedor</h4>
      </div>

      <validation-observer ref="forncededorCadastroValidation">
        <b-form class="mt-1">
          <b-row>
            <!-- NOME -->
            <b-col cols="12" md="7" lg="7">
              <b-form-group label="Nome do Fornecedor *" label-for="nome">
                <validation-provider
                  #default="{ errors }"
                  name="Nome"
                  rules="required|max:100"
                >
                  <b-form-input
                    id="nome"
                    v-model="form.fornecedor_nome"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- CPF-->
            <b-col cols="12" md="5" lg="5">
              <b-form-group label="CPF" label-for="cpf">
                <validation-provider
                  #default="{ errors }"
                  name="CPF"
                  rules="min:14"
                >
                  <cleave
                    id="cpf"
                    v-model="form.cpf"
                    class="form-control"
                    :raw="false"
                    :options="options.cpf"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- CNPJ-->
            <b-col cols="12" md="7" lg="7">
              <b-form-group label="CNPJ" label-for="cnpj">
                <validation-provider
                  #default="{ errors }"
                  name="CNPJ"
                  rules="min:18"
                >
                  <cleave
                    id="cnpj"
                    v-model="form.cnpj"
                    class="form-control"
                    :raw="false"
                    :options="options.cnpj"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- CATEGORIAS-->
            <b-col cols="12" md="5" lg="5">
              <b-form-group label="Categorias" label-for="planos">
                <validation-provider #default="{ errors }" name="Categoria">
                  <v-select
                    v-model="form.plano_id"
                    :options="despesasAgrupadas"
                    :reduce="(plano) => plano.id"
                    label="nome"
                    :selectable="(option) => option.posicao > 0"
                  >
                    <template #list-header>
                      <li
                        v-b-toggle.sidebar-invoice-add-new-customer
                        class="
                          add-new-client-header
                          d-flex
                          align-items-center
                          my-50
                          ml-1
                          text-success
                        "
                        @click="cadastrarPlano"
                      >
                        <feather-icon
                          class="text-success"
                          icon="PlusIcon"
                          size="16"
                        />
                        <span class="align-middle ml-25"
                          >Cadastrar Categoria</span
                        >
                      </li>
                    </template>

                    <template #option="{ nome, posicao }">
                      <h5
                        v-if="posicao == 0"
                        class="text-primary"
                        style="margin: 0"
                      >
                        {{ nome }}
                      </h5>
                      <em v-else class="pl-2">{{ nome }}</em>
                    </template>

                    <div slot="no-options">Categoria não encontrada!</div>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- EMAIL -->
            <b-col cols="12" md="12" lg="12">
              <b-form-group label="Email" label-for="email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="email|max:50"
                >
                  <b-form-input
                    id="email"
                    v-model="form.email"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- TEL RESIDENCIAL -->
            <b-col cols="12" md="3" lg="3">
              <b-form-group
                label="Telefone Residencial"
                label-for="tel_residencial"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Telefone Residencial"
                  rules="min:14"
                >
                  <cleave
                    id="tel_residencial"
                    v-model="form.tel_residencial"
                    class="form-control"
                    :raw="false"
                    :options="options.telComercial"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- TEL COMERCIAL -->
            <b-col cols="12" md="3" lg="3">
              <b-form-group
                label="Telefone Comercial"
                label-for="tel_comercial"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Telefone Comercial"
                  rules="min:14"
                >
                  <cleave
                    id="tel_comercial"
                    v-model="form.tel_comercial"
                    class="form-control"
                    :raw="false"
                    :options="options.telComercial"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- CEL UM -->
            <b-col cols="12" md="3" lg="3">
              <b-form-group label="Celular" label-for="celular_um">
                <validation-provider
                  #default="{ errors }"
                  name="Celular"
                  rules="min:15"
                >
                  <cleave
                    id="celular_um"
                    v-model="form.celular_um"
                    class="form-control"
                    :raw="false"
                    :options="options.celular"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- CEL 2 -->
            <b-col cols="12" md="3" lg="3">
              <b-form-group label="Celular WhatsApp" label-for="celular_dois">
                <validation-provider
                  #default="{ errors }"
                  name="Celular WhatsApp"
                  rules="min:15"
                >
                  <cleave
                    id="celular_dois"
                    v-model="form.celular_dois"
                    class="form-control"
                    :raw="false"
                    :options="options.celular"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- ENDEREÇOS -->
          <div class="d-flex mt-2">
            <feather-icon icon="MapPinIcon" size="19" />
            <h4 class="mb-0 ml-50">Endereço</h4>

            <b-alert class="ml-2" variant="warning" v-if="errorsCep" show>
              <p>Endereço não encontrado para o Cep Digitado.</p>
            </b-alert>
          </div>

          <b-row class="mt-1">
            <!-- CEP -->
            <b-col cols="12" md="4" lg="4">
              <b-form-group label="CEP" label-for="cep">
                <!--                           -->
                <validation-provider
                  #default="{ errors }"
                  name="CEP"
                  rules="min:10"
                >
                  <b-input-group>
                    <cleave
                      id="cep"
                      v-model="form.cep"
                      class="form-control"
                      :raw="false"
                      :options="options.cep"
                    />
                    <b-input-group-append>
                      <b-button variant="outline-primary" @click="buscaCep">
                        <b-spinner small v-if="spinnerCep" />
                        <span v-if="spinnerCep">Aguarde...</span>
                        <span v-else>Buscar Endereço</span></b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- ENDEREÇO -->
            <b-col cols="12" md="5" lg="5">
              <b-form-group label="Rua" label-for="endereco">
                <validation-provider
                  #default="{ errors }"
                  name="Endereço"
                  rules="max:100"
                >
                  <b-form-input
                    id="endereco"
                    v-model="form.endereco"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- NUMERO -->
            <b-col cols="12" md="3" lg="3">
              <b-form-group label="Número" label-for="numero">
                <validation-provider
                  #default="{ errors }"
                  name="Número"
                  rules="max:10"
                >
                  <b-form-input
                    id="numero"
                    v-model="form.numero"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <!-- CIDADE -->
            <b-col cols="12" md="3" lg="3">
              <b-form-group label="Cidade" label-for="cidade">
                <validation-provider
                  #default="{ errors }"
                  name="Cidade"
                  rules="max:30"
                >
                  <b-form-input
                    id="cidade"
                    v-model="form.cidade"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- UF -->
            <b-col cols="12" md="2" lg="2">
              <b-form-group label="UF" label-for="uf">
                <validation-provider
                  #default="{ errors }"
                  name="Estado"
                  rules="max:2"
                >
                  <cleave
                    id="uf"
                    v-model="form.estado"
                    class="form-control"
                    :raw="false"
                    :options="options.uf"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- BAIRRO -->
            <b-col cols="12" md="4" lg="4">
              <b-form-group label="Bairro" label-for="bairro">
                <validation-provider
                  #default="{ errors }"
                  name="Bairro"
                  rules="max:30"
                >
                  <b-form-input
                    id="bairro"
                    v-model="form.bairro"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- COMPLEMENTO -->
            <b-col cols="12" md="3" lg="3">
              <b-form-group
                label="Complemento"
                label-for="formulario-complemento"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Complemento"
                  rules="max:30"
                >
                  <b-form-input
                    id="complemento"
                    v-model="form.complemento"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="d-flex mt-2">
            <feather-icon icon="UserIcon" size="19" />
            <h4 class="mb-2 ml-50">Contato do Representante</h4>
          </div>
          <b-row>
            <!-- CONTATO -->
            <b-col lg="4" md="4">
              <b-form-group label="contato" label-for="item-name">
                <validation-provider
                  #default="{ errors }"
                  name="contato"
                  rules="max:50"
                >
                  <b-form-input
                    id="item-name"
                    v-model="form.contato"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- TEL_COMERCIAL -->
            <b-col md="2">
              <b-form-group
                label="Telefone Comercial"
                label-for="contato_tel_comercial"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Telefone Residencial"
                  rules="min:14"
                >
                  <cleave
                    id="contato_tel_comercial"
                    v-model="form.contato_tel_comercial"
                    class="form-control"
                    :raw="false"
                    :options="options.telComercial"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- CEL WHATSAPP -->
            <b-col md="2">
              <b-form-group
                label="Celular WhatsApp"
                label-for="celular_representante"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Celular WhatsApp"
                  rules="min:15"
                >
                  <cleave
                    id="celular_representante"
                    v-model="form.contato_celular"
                    class="form-control"
                    :raw="false"
                    :options="options.celular"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- CONTATO EMAIL -->
            <b-col lg="4" md="4">
              <b-form-group label="Email" label-for="email_representante">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="email|max:50"
                >
                  <b-form-input
                    id="email_representante"
                    v-model="form.contato_email"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <!-- BOTAO ENVIAR -->
            <b-col>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :disabled="submit"
                @click="cadastrarFornecedor"
              >
                <b-spinner small v-if="spinner" />
                <span v-if="spinner">Aguarde...</span>
                <span v-else>Cadastrar</span>
              </b-button>

              <b-button
                variant="outline-secondary"
                @click="$bvModal.hide('cadastrarFornecedor')"
              >
                Fechar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <ModalCadastrarPlano ref="CadastrarPlanoComponent" />
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BCard,
  BInputGroupAppend,
  BInputGroup,
  BSpinner,
  BAlert,
  BFormSelect,
  BModal,
  VBToggle,
} from "bootstrap-vue";

import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Cleave from "vue-cleave-component";
import ModalCadastrarPlano from "../../financeiro/ModalCadastrarPlano.vue";

// eslint-disable-next-line import/no-extraneous-dependencies
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    BInputGroupAppend,
    BInputGroup,
    BSpinner,
    BAlert,
    BModal,
    BFormSelect,
    BFormDatepicker,
    VBToggle,
    vSelect,
    ModalCadastrarPlano,
  },

  directives: { "b-toggle": VBToggle },

  data() {
    return {
      required,
      email,
      getErrors: {
        hasMessage: false,
        color: "danger",
        message: "",
        messageArray: "",
      },
      form: {
        id: "",
        fornecedor_nome: "",
        cpf: "",
        cnpj: "",
        email: "",
        tel_comercial: "",
        tel_residencial: "",
        celular_um: "",
        celular_dois: "",
        saldo: "",
        cep: "",
        endereco: "",
        numero: "",
        cidade: "",
        bairro: "",
        complemento: "",
        estado: "",
        contato: "",
        contato_tel_comercial: "",
        contato_celular: "",
        contato_email: "",
        plano_id: "",
      },
      spinner: false,
      submit: false,
      spinnerCep: false,
      errorsCep: false,
      errors: false,
      options: {
        cpf: {
          delimiters: [".", ".", "-"],
          blocks: [3, 3, 3, 2],
          numericOnly: true,
        },
        cnpj: {
          delimiters: [".", ".", "/", "-"],
          blocks: [2, 3, 3, 4, 2],
          numericOnly: true,
        },

        telComercial: {
          delimiters: ["(", ") ", "-"],
          blocks: [0, 2, 4, 4],
          numericOnly: true,
        },

        celular: {
          delimiters: ["(", ") ", "-"],
          blocks: [0, 2, 5, 4],
          numericOnly: true,
        },
        cep: {
          delimiters: [".", "-"],
          blocks: [2, 3, 3],
          numericOnly: true,
        },
        uf: {
          blocks: [2],
          uppercase: true,
        },
      },
    };
  },

  computed: {
    ...mapGetters("planos", ["despesasAgrupadas"]),
  },

  methods: {
    cadastrarPlano() {
      this.$refs.CadastrarPlanoComponent.SetPlanoId(0);
      this.$bvModal.show("cadastrarPlano");
    },
    cadastrarFornecedor() {
      this.$refs.forncededorCadastroValidation.validate().then((success) => {
        if (success) {
          this.spinner = true;
          this.submit = true;
          this.errors = false;

          this.$http
            .post("adm/fornecedores", this.form)
            .then((res) => {
              this.$store.commit("fornecedores/ADICIONAR_FORNECEDOR", res.data);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Fornecedor Cadastrado com Sucesso!",
                  icon: "InfoIcon",
                  variant: "success",
                },
              });
              this.getErrors = false;
              this.$refs.forncededorCadastroValidation.reset();
              this.resetarCampos();
            })
            .catch((error) => {
              let res = this.$responseMessages.getMessage(error, null);
              this.getErrors = res;
            })
            .finally(() => {
              this.spinner = false;
              this.submit = false;
            });
        }
      });
    },

    resetarCampos() {
      this.form = {
        id: "",
        fornecedor_nome: "",
        cpf: "",
        cnpj: "",
        email: "",
        tel_comercial: "",
        tel_residencial: "",
        celular_um: "",
        celular_dois: "",
        saldo: "",
        cep: "",
        endereco: "",
        numero: "",
        cidade: "",
        bairro: "",
        complemento: "",
        estado: "",
        contato: "",
        contato_tel_comercial: "",
        contato_celular: "",
        contato_email: "",
        plano_id: "",
      };
    },
    buscaCep() {
      let count = this.form.cep.length;
      if (count > 9) {
        this.spinnerCep = true;
        this.$http
          .get(`adm/cep/${this.form.cep}`)
          .then((res) => {
            if (!res.data.error) {
              this.errorsCep = false;

              if (res.data.cep.erro) {
                this.errorsCep = true;
              }
              this.form.endereco = res.data.cep.logradouro;
              this.form.bairro = res.data.cep.bairro;
              this.form.cidade = res.data.cep.localidade;
              this.form.estado = res.data.cep.uf;
            }
          })
          .catch((res) => {
            this.errorsCep = true;
          })
          .finally(() => {
            this.spinnerCep = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
